import React, {useCallback, useState} from 'react';
import './productPage.scss'
import ManageProductsContainer from '../../Components/ManageProductContainer/ManageProductContainer';
import ProductTable from '../../Components/ProductTable/ProductTable';

interface ProductPageProps{
  
}

const ProductPage: React.FC<ProductPageProps> = props => {
  const [products, setProducts] = useState(null);
  const [search, setSearch] = useState('');


  return (
    <div className="ProductPage">
        <ManageProductsContainer
            setProducts={setProducts}
            products={products}
            setSearch={setSearch}
        />
        <ProductTable
            setProducts={setProducts}
            products={products}
            searchValue={search}
        />
    </div>
  );
}

export default ProductPage;

import { isLogged, isntLogged } from './counter.types';

const initialState = {
    loggedIn: false,
};

const counterReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case isLogged:
            return {

              ...state, loggedIn: true,

            };

        case isntLogged:

            return {
              ...state, loggedIn: false,

            };

          default: return state;

    }

};

export default counterReducer;
import axios from 'axios';

let isAxiosInitialized: boolean = false;
let initializedAxiosInstance: any = null;
const baseAppURL: string = 'chiccoks-api.stoku.app/api';

export const axiosInstance = () => {
  if (!isAxiosInitialized || !initializedAxiosInstance) {
    initAxiosInstance();
    isAxiosInitialized = true;
  }

  return initializedAxiosInstance;
};

export const loginWithAxios = (credentials) => {
  return axios.post(`https://${baseAppURL}/auth/login`,credentials)
}

const initAxiosInstance = () => {
  let token: string | any;
  let baseURL: string;
  
  try {
    token = localStorage.getItem('token');
  } catch (e) {
    console.error(`Error while trying to parse state from session:\n${e}`);
  }

  baseURL = `https://${baseAppURL}`;
  const newInstance = axios.create({
    baseURL,
  });

  newInstance.interceptors.request.use(config => {
    config.headers['Authorization'] = 'Bearer ' + token;

    return config;
  }, error => {
    console.error(`Got Request Error:\n${error}`);
  });

  newInstance.interceptors.response.use(function(response) {
    if (response.status > 200) {
      console.error(`Got HTTP-Status ${response.status}`);
    }
    return response;
  }, function(error) {
    console.error(`Got Error Response:\n${error}`);
    return Promise.reject(error);
  });

  initializedAxiosInstance = newInstance;
};

import React, { useEffect } from 'react';
import './login.scss'
import Input from '../../Components/Input/Input'
import Button from '../../Components/Button/Button'
import { login } from '../../services/auth.service';
import {useState} from 'react'
import { toast } from 'react-toastify';
import {
  logIn,
  logOut,
} from '../../Components/Redux/Counter/counter.actions';
import { connect } from 'react-redux';

interface LoginPageProps{
  loggedIn: boolean;
  logIn(): void;
}

const LoginPage: React.FC<LoginPageProps> = props => {

  const [name,setName] = useState('');
  const [password,setPassword] = useState('');

  let credentials = {
    name: name , 
    password: password
  }

  const getToken = () =>{
    login(credentials).then((result)=>{
      if(result.data.success){
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('role', result.data.role)
        props.logIn();
      }else{
        toast.error(result.data.message)
      }
      
    }).catch((error)=>{
      console.log(error);
    });
  }

  const saveName = (e) =>{
    setName(e.target.value)
  }
  const savePassword = (e) =>{
    setPassword(e.target.value)
  }

  useEffect(()=>{
    if(localStorage.getItem('token')){
      props.logIn()
    }
  },[])

  return (
    
    <div className="loginPageContainer">
        <div className="loginPageCredentials">
            <h1>Login Page</h1>
            <input type="text" placeholder="Name" onChange={saveName} />
            <input type="password" placeholder="Password" onChange={savePassword} />
            <div className="rememberMeLoginBtnContainer">
                <div className="rememberMeContainer">
                    <input type="checkbox" id="rememberMe" />
                    <label htmlFor="rememberMe">Remember Me!</label>
                </div>
                <Button buttonText="Login" onClick={getToken} />
            </div>
        </div>
    </div>
    
  );
}

const mapStateToProps = state => {
  return {
    loggedIn: state.counter.loggedIn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logIn: () => dispatch(logIn()),

    logOut: () => dispatch(logOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)

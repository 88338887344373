import React, {useEffect, useRef} from 'react';
import './tableRow.scss';
import {useState} from 'react';
import {changeStock, deleteProduct, updateProduct} from '../../services/product.service';
import {toast} from 'react-toastify';
import ReactToPrint from 'react-to-print';
import {PrintProducts} from '../../PrintComponents/PrintProducts';
import Loader from '../Loader/Loader';


interface TableRowProps {
  dataFiller: any;

  removeProductFromArr(productId: number): void;
}

const TableRow: React.FC<TableRowProps> = props => {
  const componentRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [isChangingStock, setIsChangingStock] = useState(false);

  const [product, setProduct] = useState({
    id: 0,
    name: '',
    price: 0,
    stock: 0,
    shop: {
      name: '',
    },
    category: {
      name: '',
    },
  });

  useEffect(() => {
    setProduct(props.dataFiller);
  }, []);

  const onValueChange = (field: string, value: any) => {
    try {
      let newProduct = {
        ...product,
        [field]: value,
      };

      setProduct(newProduct);
    } catch (e) {
      console.log(e);
    }

  };

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleCloseEditMode = (e: any) => {
    if (e.key === 'Enter') {
      setEditMode(!editMode);
      updateProductApi();
    }
  };

  const updateProductApi = () => {
    updateProduct(product.id, product).then((response: any) => {
      toast.success('Produkti u ndrrua me sukses');
    }).catch((e: any) => {
      toast.error('Produkti deshtoi te ndrryshohet');
      console.log(e);
    });
  };

  const handleDeleteProduct = () => {
    deleteProduct(product.id).then((response) => {
      props.removeProductFromArr(product.id);
      toast.success('Produkti u fshi me sukses!');
    }).catch(() => {
      toast.error('Produkti deshtoi te fshihet!');
    });
  };

  const addOrSubtractStock = (subtract: boolean) => {
    setIsChangingStock(true);
    let shouldSubtract = 0;
    shouldSubtract = handleStockChange(subtract);

    changeStock(product.id, shouldSubtract).then((response) => {
      if (response.data == 1) {
        toast.success('Stoku u ndrrua me sukses!');
      } else {
        handleStockChange(!subtract);
        toast.error('Kontaktoni supportin !');
      }
    }).catch(() => {
      handleStockChange(!subtract);
      toast.error('Kontaktoni supportin !');
    });

    setIsChangingStock(false);
  };

  const handleStockChange = (subtract: boolean) => {
    let shouldSubtract = 0;

    if (subtract) {
      shouldSubtract = 1;
      onValueChange('stock', (product.stock - 1));
    } else {
      onValueChange('stock', (product.stock + 1));
    }

    return shouldSubtract;
  }

  return (

      <tr >
        <td>
          <p>{product.id}</p>
        </td>
        <td>
          {!editMode ?
              <p>{product.name}</p>
              :
              <input type="text" value={product.name} onKeyDown={handleCloseEditMode}
                     onChange={(target) => onValueChange('name', target.target.value)}/>
          }
        </td>
        <td>
          {!editMode ?
              <p>{product.category.name}</p>
              :
              <input type="text" value={product.category.name} onKeyDown={handleCloseEditMode}
                     onChange={(target) => onValueChange('dsc', target.target.value)}/>
          }
        </td>
        <td>
          {!editMode ?
              <p>{product.shop.name}</p>
              :
              <input type="text" value={product.shop.name} onKeyDown={handleCloseEditMode}
                     onChange={(target) => onValueChange('shop', target.target.value)}/>
          }
        </td>
        <td>
          {!editMode ?
              <p>{product.price}€</p>
              :
              <input type="text" value={product.price} onKeyDown={handleCloseEditMode}
                     onChange={(target) => onValueChange('price', target.target.value)}/>
          }
        </td>
        <td>
          {!editMode ?
              <p>{product.stock}</p>
              :
              <input type="text" value={product.stock} onKeyDown={handleCloseEditMode}
                     onChange={(target) => onValueChange('stock', target.target.value)}/>
          }
        </td>
        <td>
          <p>{product.price * product.stock}€</p>
        </td>
        <td>
          {isChangingStock
              ?
              <Loader/>
              :
              <div>
                <span className="fas fa-plus"
                      onClick={() => {
                        addOrSubtractStock(false);
                      }
                      }
                      style={{ marginRight: '15%' }}/>
                <span
                    className="fas fa-minus"
                    onClick={() => {
                      addOrSubtractStock(true);
                    }
                    }
                />
              </div>
          }
        </td>
        <td>
          <ReactToPrint
              trigger={() => <i className="fas fa-print" style={{ marginRight: '15%' }}/>}
              content={() => componentRef.current}/>
          <div style={{ display: 'none' }}>
            <PrintProducts
                productName={product.name}
                productPrice={product.price}
                productId={product.id}
                ref={componentRef}
            />
          </div>
          <span className={'fa fa-close'} onClick={() => handleDeleteProduct()}/>
        </td>
      </tr>

  );
};

export default TableRow;

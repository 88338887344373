import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ProductPage from '../../Pages/ProductPage/ProductPage'
import LoginPage from '../../Pages/Login/Login'
import {Switch , Route} from 'react-router-dom'
import './routes.scss'
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import CategoriesPage from '../../Pages/CategoriesPage/CategoriesPage';
import ScannerPage from '../../Pages/ScannerPage/ScannerPage';
import SalePage from '../../Pages/SalePage/SalePage';

interface RouteProps{
  loggedIn: boolean
}

const Routes: React.FC<RouteProps> = props =>{  


  return (
    
      <div className="routesWrapper">
        {
          props.loggedIn
          ?
          <>
          <Sidebar/>
          <Switch>
            <Route exact path="/produktet">
              <ProductPage />
            </Route>
            <Route path="/categories">
              <CategoriesPage />
            </Route>
            <Route path="/sales">
              <SalePage />
            </Route>
            <Route path="/">
              <ScannerPage/>
            </Route>
            <Route render={()=> <div>Not found</div>}/>
          </Switch>
          </>
          :
          <LoginPage/>
        }
        <ToastContainer 
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
  );
};

const mapStateToProps = state => {
  return {
    loggedIn: state.counter.loggedIn,
  }
}

export default connect(mapStateToProps)(Routes);

import React from 'react';
import Input from '../Input/Input'
import Button from '../Button/Button'
// import {Switch , Route} from 'react-router-dom'
import './loader.scss'
import logo from "../../assets/icons/dress.svg"

interface LoaderProps{
  style?: React.CSSProperties;
}

const Loader: React.FC<LoaderProps> = props => {  


  return (
    
    <div style={{ ...props.style }} className="loaderContainer">
          <img src={logo} alt=""/>
    </div>
    
  );
}

export default Loader;

import React from 'react';
import { NavLink } from 'react-router-dom';
import './sidebarComponent.scss'

interface Props{
    menuItem : any
}

const SidebarComponent: React.FC<Props> = props => {


  return (
    
    <div className="sideBarComponent">
        <NavLink exact to={props.menuItem.path} activeClassName="active">
          <div className="curves upperCurve">
            <div></div>
          </div>
          <i className="fas fa-stroopwafel"></i>
          <h1>{props.menuItem.name}</h1>
          <div className="curves lowerCurve">
            <div></div>
          </div>
        </NavLink> 
    </div>
    
  );
}

export default SidebarComponent;
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import './manageProductContainer.scss';
import {createProduct} from '../../services/product.service';
import {toast} from 'react-toastify';
import Select from '../Select/Select';
import {loadAllCategories} from '../../services/category.service';
import _ from 'lodash';

interface ManageProductContainerProps {
  products: any[];

  setProducts(products: any[]): void;

  setSearch(search: string): void;
}

const availableShops = [
  {
    id: 1,
    name: 'Qender',
  },
  {
    id: 2,
    name: 'Nertil',
  },
];


const ManageProductsContainer: React.FC<ManageProductContainerProps> = props => {
  const [product, setProduct] = useState({
    name: '',
    price: 0,
    shop: 1,
    category: 0,
    stock: 0,
  });
  const [creating, setCreating] = useState(false);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const sendQuery = (query) => {
    props.setSearch(query);
  }

  const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 1000), []);

  useEffect(() => {
    loadAllCategories().then((response: any) => {
      handleProductChange('category', response.data[0].id);
      setAvailableCategories(response.data);
    }).catch((e: any) => {
      toast.error('Deshtoi ti mbush kategorite');
      console.log(e);
    });
  }, []);

  const createNewProduct = () => {
    setCreating(true);
    createProduct(product).then((response: any) => {
      let newProducts = [response.data, ...props.products];

      props.setProducts(newProducts);
      toast.success('Produkti u shtua me sukses!');
    }).catch((e: any) => {
      toast.error('Mbushi te gjitha fushat!');
      console.log(e);
    });
    setCreating(false);
  };

  const handleProductChange = (field: string, value: any) => {
    let newProduct: any = {
      ...product,
      [field]: value,
    };

    setProduct(newProduct);
  };

  const onSearchChange = e => {
    setSearchQuery(e);
    delayedQuery(e);
  };


  return (
      <div className="manageProductsContainer">
        <div className="createProductContainer">
          <Input
              onChange={(value) => handleProductChange('name', value)}
              onKeyDown={(event) => console.log('placeholder')}
              inputValue=""
              inputPlaceholder="Product Name"
              inputType="text"/>
          <Input
              onChange={(value) => handleProductChange('stock', parseInt(value))}
              onKeyDown={(event) => console.log('placeholder')}
              inputValue=""
              inputPlaceholder="Product Stock"
              inputType="number"/>
          <Input
              onChange={(value) => handleProductChange('price', value)}
              onKeyDown={(event) => console.log('placeholder')}
              inputValue=""
              inputPlaceholder="Product Price"
              inputType="text"
          />
          <Select
              onChange={(value) => {
                handleProductChange('category', value);
              }}
              options={availableCategories}/>
          <Select
              onChange={(value) => {
                handleProductChange('shop', value);
              }}
              options={availableShops}/>
          <Button
              working={creating}
              onClick={createNewProduct}
              buttonText="Create"/>
        </div>
        <div className="searchContainer">
          <Input
              onKeyDown={() => console.log('test')}
              onChange={onSearchChange}
              inputValue="" inputPlaceholder="Search" inputType="text"
          />
          <div className="btn lanas-btn">
            <i className="fas fa-search"></i>
          </div>
        </div>
      </div>

  );
};

export default ManageProductsContainer;

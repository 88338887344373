import React from 'react';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import Routes from './Components/Routes/Routes';

function App() {
  return (
    <div className="App">
      
      <Routes/>
    </div>
  );
}

export default App;

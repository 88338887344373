import React, {useState} from 'react';
import './scannerPage.scss'
import Input from '../../Components/Input/Input';
import {findOneProduct, reduceStock} from '../../services/product.service';
import {toast} from 'react-toastify';
import Button from '../../Components/Button/Button';

interface Props{

}

const ScannerPage: React.FC<Props> = props => {
  const [scannedProducts, setScannedProducts] = useState([]);
  const [emptyValue, setEmptyValue] = useState('');
  const [total, setTotal] = useState(0);

  const addToScannedProducts = (product: any) => {
    let newScannedProducts = [...scannedProducts];

    newScannedProducts.push({
      product: product,
      id: product.id,
      price: null
    });

    setTotal(total + product.price)
    setScannedProducts(newScannedProducts);
  }

  const handleChangeToScannedProducts = (index: number, field: string, value: any) => {
    let newScannedProducts = [...scannedProducts];

    newScannedProducts[index][field] = value;

    setScannedProducts(newScannedProducts);
  }

  const getTotalPrice = (): number => {
    let total = 0;

    scannedProducts.forEach((product: any) => {
      if(product.price){
        total += product.price;
      }else{
        total += product.product.price;
      }
    });

    return total;
  }

  return (
      <div className="scannerPage">
        <div className='scannerContainer'>
          <h1 className='scannerHeader'>Skeno</h1>
          <div className='inputContainer'>
            <Input
                className="scannerInput"
                inputType={'number'}
                inputValue={emptyValue}
                onChange={() => console.log()}
                onKeyDown={(event) => {
                  if(event.key == 'Enter'){
                    findOneProduct(event.target.value).then((response) => {
                      setEmptyValue('');
                      if(response.data === null){
                        toast.error('Ky produkt nuk ekziston!');
                      }else{
                        addToScannedProducts(response.data);
                      }
                    }).catch((e: any) => {
                      toast.error('Ky produkt nuk ekziston ose nuk ka ne stok!');
                      console.log(e);
                    });
                  }
                }}
            />
            <Button
                className='scannerButton'
                buttonText='Mbaro skenimin'
                onClick={() => {
                  reduceStock(scannedProducts).then((response) => {
                    if(response.data){
                      toast.success('Produktet u skenuan me sukses!');
                      setScannedProducts([]);
                    }
                  }).catch((e) => {
                    toast.error('Diqka eshte gabim!')
                    console.log(e)
                  })
                }}
            />
          </div>

          {scannedProducts.length > 0 &&
            <>
              <div className='scannerTotal'>
                <p>Totali: {getTotalPrice()}€</p>
              </div>
              <div className='productHeader'>
                <p className='tableHeaderText'>ID</p>
                <p className='tableHeaderText'>Emri</p>
                <p className='tableHeaderText'>Cmimi</p>
              </div>
              {scannedProducts.map((scanned, index) => {
                return <div className='productsTable'>
                  <div className='productRow'>
                    <div className='tableProductData'>
                      <p >{scanned.id}</p>
                    </div>
                    <div className='tableProductData'>
                      <p >{scanned.product.name}</p>
                    </div>
                    <div className='tableProductData'>
                      <Input
                          className="priceInput"
                          inputType={'number'}
                          inputValue={scanned.product.price}
                          onChange={(value) => {
                              handleChangeToScannedProducts(index, 'price', parseInt(value));
                            }
                          }
                          doNotDeleteOnEnter
                          onKeyDown={(event) => {
                          }}
                      />
                    </div>
                  </div>
                </div>
              })
              }
            </>
          }
          </div>
      </div>
  );
}

export default ScannerPage;

import React, {useEffect, useState} from 'react';
import './saleTable.scss';
import {connect} from 'react-redux';
import Loader from '../Loader/Loader';
import {toast} from 'react-toastify';
import {loadSales} from '../../services/sale.service';
import SaleTableRow from './saleTableRow';
import {UserRole} from '../../enums/userRole';

interface ProductTableProps {
}

const SaleTable: React.FC<ProductTableProps> = props => {
      const [sales, setSales] = useState(null);
      const [dailySales, setDailySales] = useState({
        total: 0,
        discounted: 0,
      });
      const [toggleState , setToggleState] = useState('qendra');

      const toggleShitjetDitore = () =>{
        toggleState == 'qendra'? setToggleState('nertil') : setToggleState('qendra')
      }

      useEffect(() => {
        loadSales().then((response: any) => {
          setSales(response.data.sales);
          setDailySales(response.data.dailySales);
        }).catch((e: any) => {
          toast.error('Shfaqja e shitjeve deshtoi');
          console.log(e);
        });
      }, []);

      const removeSaleFromArr = (saleId: number) => {
        const filterArray = sales.filter((product) => {
          return product.id !== saleId;
        });

        setSales(filterArray);
      };

      const renderBody = () => {
        return <>
          <div className="shitjetDitoreContainer">
            <div className="totalSales">
              <p>
                Shitjet totale: <span> {dailySales.total}€</span>
              </p>
              <p style={{ marginTop: '10px' }}>
                Zbritja e aplikuar: <span style={{ color: 'red'}}> {dailySales.discounted}€</span>
              </p>
            </div>
            <div className="toggleContainer">
              <button className={`toggle-button ${toggleState === 'qendra'? "" : "active" }`} onClick={toggleShitjetDitore}>
                <div> 
                </div>
                <p className="qendra">Qendra</p>
                <p className="nertil">Nertil</p>
              </button>
            </div>
          </div>
          <div className="saleTable">
            <table>
              <tr>
                <th>ID</th>
                <th>Emri</th>
                <th>Perdorusi</th>
                <th>Qmimi</th>
                <th>Koha e shitjes</th>
                {localStorage.getItem('role') === "0" && <th></th>}
              </tr>
              {sales.map((element: any) =>
                  <SaleTableRow
                      removeSaleFromArr={removeSaleFromArr}
                      key={element.id} dataFiller={element}
                  />,
              )}
            </table>
          </div>
        </>;
      };

      return (
          <>
            {sales
                ?
                renderBody()
                :
                <Loader style={{ width: '100%' }}/>
            }
          </>

      );
    }
;

const mapStateToProps = (state: any) => {
      return {};
    }
;

const mapDispatchToProps = (dispatch: any) => {
      return {};
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(SaleTable);

import React, {useState} from 'react';

import './salePage.scss'
import SaleTable from '../../Components/SaleTable/saleTable.component';

interface Props{

}

const SalePage: React.FC<Props> = props => {

  return (
      <div className="salePage">
        <SaleTable />
      </div>

  );
}

export default SalePage;

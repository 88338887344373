import React from 'react';
import classNames from 'classnames';


interface ButtonProps{
  buttonText : any;
  working?: boolean;
  className?: string;
  onClick(): void;
}

const Button: React.FC<ButtonProps> = props => {

  return (
    
      <button
          className={classNames({
            'btn lanas-btn': true,
            [props.className]: true,
          })}
          disabled={props.working}
          onClick={() => props.onClick()}>
        {props.buttonText}
      </button>
    
  );
}

export default Button;

import React from 'react';
import './sidebar.scss';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import logo from '../../assets/icons/dress.svg';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {logOut} from '../Redux/Counter/counter.actions';
import {connect} from 'react-redux';
import {UserRole} from '../../enums/userRole';

interface Props{
  logOut(): void
}

const Sidebar: React.FC<Props> = props => {
  const sidebarMenus = [
    {
      name:"Produktet",
      path:"/produktet",
      role: [UserRole.Admin],
    },
    {
      name:"Kategorite",
      path:"/categories",
      role: [UserRole.Admin],
    },
    {
      name:"Shitjet",
      path:"/sales",
      role: [UserRole.Admin, UserRole.User],
    },
    {
      name: 'Skaneri',
      path:"/",
      role: [UserRole.Admin, UserRole.User],
    }
  ]

  const clearToken = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('role')
    toast.info('See you next time!')
    props.logOut()
  }

  return (
    
      <div className="sideBar">
        <div className="logoContainer">
          <img src={logo} alt=""/>
          <h1>Lanes</h1>
        </div>
        {sidebarMenus.map(el => {
            let role = parseInt(localStorage.getItem('role'));
            let giveAcces = false;
            el.role.forEach((elRole: any) => {
              if(elRole === role){
                giveAcces = true;
              }
            });

          if(giveAcces){
            return <SidebarComponent
                menuItem={el}
            />
          }
        })}
        <button className="createButton" onClick={clearToken}>Log Out</button>
        
      </div>
    
  );
}

const mapStateToProps = state => {
  return {
    loggedIn: state.counter.loggedIn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';


interface InputProps{
  inputType : string;
  inputPlaceholder?: string;
  inputValue: string | number;
  className?: string;
  isNumber?: boolean;
  doNotDeleteOnEnter?: boolean;

  onChange(event: any): void;
  onKeyDown(event: any): void;
}

const Input: React.FC<InputProps> = props => {
  const [currentValue, setCurrentValue] = useState(props.inputValue);

  useEffect(() => {
    setCurrentValue(props.inputValue);
  }, [props.inputValue])

  return (
      <>
      <input
            className={classNames({
              'lanas-input': true,
              [props.className]: true,
            })}
            type={props.inputType}
            placeholder={props.inputPlaceholder}
            value={currentValue}
            onKeyDown={(event) => {
              if(event.key == 'Enter'){
                if(!props.doNotDeleteOnEnter){
                  setCurrentValue('');
                }
              }
              props.onKeyDown(event);
            }}
            onChange={(e: any) => {
               setCurrentValue(e.target.value)
               if(props.onChange){
                 props.onChange(e.target.value);
               }
            }}
      />
      </>
  );
}

export default Input;

import React, {useEffect, useRef} from 'react';
import './saleTable.scss';
import {useState} from 'react';
import {deleteSale} from '../../services/sale.service';
import {toast} from 'react-toastify';


interface Props {
  dataFiller: any;

  removeSaleFromArr(productId: number): void;
}

const SaleTableRow: React.FC<Props> = props => {
  const [sale, setSale] = useState({
    id: 0,
    productId: 0,
    name: '',
    userName: '',
    price: 0,
    createdAt: ''
  });

  useEffect(() => {
    setSale(props.dataFiller);
  }, []);

  const handleDeleteProduct = () => {
    deleteSale(sale.id).then((response) => {
      props.removeSaleFromArr(sale.id);
      toast.success('Shitja u fshi me sukses!');
    }).catch(() => {
      toast.error('Shitja deshtoi te fshihet!');
    });
  };

  const getDate2HoursAhead = (givenDate) => {
    const currentDate = new Date(givenDate);
    currentDate.setHours(currentDate.getHours() + 1);
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    const seconds = ('0' + currentDate.getSeconds()).slice(-2);

    const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;  
    return formattedDate;
  }

  return (

      <tr>
        <td>
          <p>{sale.productId}</p>
        </td>
        <td>
          <p>{sale.name}</p>
        </td>
        <td>
          <p>{sale.userName}</p>
        </td>
        <td>
          <p>{sale.price}€</p>
        </td>
        <td>
          <p>{getDate2HoursAhead(sale.createdAt)}</p>
        </td>
        {localStorage.getItem('role') === "0" && <td>
          <span className={'fa fa-close'} onClick={() => handleDeleteProduct()}/>
        </td>}
      </tr>

  );
};

export default SaleTableRow;

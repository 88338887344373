import {axiosInstance} from '../utils/api';


const getStoreIdFromString = (store: string) => {
  let storeId = 1;
  if(store === 'qendra'){
    storeId = 1
  }else {
    storeId = 2;
  }

  return storeId;
}

export const loadProducts = (page: number = 1, productId: string = null, store: string = "") => {
  return axiosInstance().get(`/product/show?page=${page}&product_id=${productId}&store_id=${getStoreIdFromString(store)}`);
}

export const loadProductTotals = (store: string = "") => {
  return axiosInstance().get(`/product/getTotal/${getStoreIdFromString(store)}`);
}

export const createProduct = (data: any) => {
  return axiosInstance().post(`/product/create`, data);
}
export const updateProduct = (productId: number, product: any) => {
  return axiosInstance().post(`/product/update/${productId}`, { product: product});
}

export const deleteProduct = (productId: number) => {
  return axiosInstance().delete(`/product/delete/${productId}`);
}

export const findOneProduct = (productId: number) => {
  return axiosInstance().get(`/product/findOne/${productId}`);
}

export const reduceStock = (reducedStock: any[]) => {
  return axiosInstance().post(`/product/reduce/stock` , { reducedStock: reducedStock})
}

export const changeStock = (id: number, subtract: number) => {
  return axiosInstance().put(`/product/changeStock/${id}/${subtract}`);
}


import React, {useState} from 'react';
import Input from '../Input/Input'
import Button from '../Button/Button'
// import {Switch , Route} from 'react-router-dom'
import './manageCategoriesContainer.scss'
import {createCategory} from '../../services/category.service';
import {toast} from 'react-toastify';

interface ManageCategoriesContainerProps{
  categories: any[];
  setCategories(categories: any[]): void;
}

const ManageCategoriesContainer: React.FC<ManageCategoriesContainerProps> = props => {
  const [categories, setCategories] = useState({
    name: ''
  });
  const [creating , setCreating] = useState(false);

  const createNewCategories = () =>{
    setCreating(true);
    createCategory(categories).then((response: any) => {
      let newCategories = [response.data, ...props.categories];

      props.setCategories(newCategories);
      toast.success('Kategoria u shtua me sukses!');
    }).catch((e: any) => {
      toast.error('Kategoria deshtoi te krijohej ');
      console.log(e);
    });
    setCreating(false);
  }

  const handleCategoriesChange = (field: string, value: any) =>{
    let newCategories: any = {
      ...categories,
      [field]: value
    }

    setCategories(newCategories);
  }

  return (
    <div className="ManageCategoriesContainer">
        <div className="createCategoriesContainer">
            <Input
                onChange={(value) => handleCategoriesChange('name', value)}
                onKeyDown={(value) => console.log('placeholder')}
                inputValue=''
                inputPlaceholder="Category Name"
                inputType="text" 
            />
            <Button
                working={creating}
                onClick={createNewCategories}
                buttonText="Create" />
        </div>

    </div>

  );
}

export default ManageCategoriesContainer;

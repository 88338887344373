import React, {useEffect, useState} from 'react';
import './select.scss'


interface SelectProps{
  options?: any[];

  onChange(value: any): void;
}

const Select: React.FC<SelectProps> = props => {

  return (
     <div className="selectContainer">
          <select
              onChange={(e) => {
                if(props.onChange){
                  props.onChange(e.target.value);
                }
              }}
              name=""
              id="">
           { props.options &&
              props.options.map((option) => {
                return <option value={option.id}>{option.name}</option>
              })
            }
        </select>
     </div>
  );
}

export default Select;

import React, {useEffect, useRef} from 'react';
import {useBarcode} from '@createnextapp/react-barcode';
import './printComponents.scss'

interface ProductBarcodeProps{
  barcodeId: number;
  productName: string;
  productPrice: number;
}

const ProductBarcode: React.FC<ProductBarcodeProps> = props => {
  const { inputRef } = useBarcode({
    value: JSON.stringify(props.barcodeId),
    options: {
      displayValue: false
    }
  });

  return (
      <>
        <div className='barcodeContainer'>
          <div className='barcodeText'>
            <p className='productName'>{props.productName}</p>
          </div>
          <img ref={inputRef} />
          <div className='barcodeText'>
            <p className='productPrice'>{props.productPrice}€</p>
            <p className='productId'>{props.barcodeId}</p>
          </div>
        </div>
      </>
  );
}

export default ProductBarcode;

import React, {useEffect, useState} from 'react';
import './categoriesTable.scss';
import Loader from '../Loader/Loader';
import {toast} from 'react-toastify';
import {deleteCategory, loadCategories} from '../../services/category.service';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Clear} from '@mui/icons-material';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {connect} from 'react-redux';

interface CategoriesTableProps {
  categories: any[];

  setCategories(categories: any): void;
}

const columns = (deleteFunction: (id: number) => void): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Emri',
      flex: 1,
    },
    {
      field: 'products_count',
      headerName: 'Numri i produkteve',
      flex: 1,
    },
    {
      field: '',
      headerName: 'Fshije',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (gridRowParams) => {
        return <div onClick={() => deleteFunction(gridRowParams.row.id)}>
          <Clear/>
        </div>;
      },
    },
  ];
};

const CategoriesTable: React.FC<CategoriesTableProps> = props => {
  const [shopId, setShopId] = useState(1);

  useEffect(() => {
    props.setCategories(undefined);
    loadCategories(shopId).then((response: any) => {
      props.setCategories(response.data.data);
    }).catch((e: any) => {
      toast.error('Shfaqja e produkteve deshtoi');
    });
  }, [shopId]);

  const deleteCategoryAction = (id: number) => {
    deleteCategory(id).then(() => {
      toast.success('Kategoria u fshi me sukses');
    }).catch((e) => {
      console.log('a je ka hin qitu mer');
      toast.error('Kategoria nuk mundet me u fshi per shkak qe ka produkte ne te ');
    });
  };

  const handleAlignment = (
      event: React.MouseEvent<HTMLElement>,
      newShopId: number | null,
  ) => {
    setShopId(newShopId);
  };

  const renderBody = () => {
    return (
        <div className="CategoriesTable">
          <div className="buttonToggleContainer">
            <ToggleButtonGroup
                value={shopId}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
            >
              <ToggleButton value={1} aria-label="left aligned">
                Qender
              </ToggleButton>
              <ToggleButton value={2} aria-label="left aligned">
                Nertil
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {props.categories
              ? <DataGrid
                  rows={props.categories}
                  columns={columns(deleteCategoryAction)}
                  pageSize={10}
                  disableSelectionOnClick
                  autoHeight
              />
              : <Loader/>
          }
        </div>
    );
  }
;

return (
  <>
    {renderBody()}
  </>
);
};


const mapStateToProps = (state: any) =>
  {
    return {};
  }
;

const mapDispatchToProps = (dispatch: any) =>
  {
    return {};
  }
;

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTable);

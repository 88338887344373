import React, {useState} from 'react';
import './categories.scss'
import ManageCategoriesContainer from '../../Components/ManageCategoriesContainer/ManageCategoriesContainer';
import CategoriesTable from '../../Components/CategoriesTable/CategoriesTable';

interface CategoriesPageProps{
  
}

const CategoriesPage: React.FC<CategoriesPageProps> = props => {
  const [categories, setCategories] = useState(null);

  return (
    <div className="CategoriesPage">
        <ManageCategoriesContainer
            setCategories={setCategories}
            categories={categories}
        />
        <CategoriesTable
            setCategories={setCategories}
            categories={categories}
        />
    </div>
  );
}

export default CategoriesPage;

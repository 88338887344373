import React, {useEffect, useState} from 'react';
import './productTable.scss';
import {connect} from 'react-redux';

import TableRow from '../TableRow/TableRow';
import {loadProducts, loadProductTotals} from '../../services/product.service';
import Loader from '../Loader/Loader';
import {toast} from 'react-toastify';
import {Pagination} from '@material-ui/lab';

interface ProductTableProps {
  products: any[];
  searchValue?: string;

  setProducts(products: any): void;
}

const ProductTable: React.FC<ProductTableProps> = props => {
      const [maxPages, setMaxPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(1);
      const [toggleStore, setToggleStore] = useState('qendra');
      const [totalStock, setTotalStock] = useState(undefined);

      useEffect(() => {
        props.setProducts(null);
        loadProducts(currentPage, props.searchValue, toggleStore).then((response: any) => {
          props.setProducts(response.data.data);
          setMaxPages(response.data.last_page);
        }).catch((e: any) => {
          toast.error('Shfaqja e produkteve deshtoi');
        });

        loadProductTotals(toggleStore).then((response) => {
          setTotalStock(response.data)
        })
      }, [currentPage, props.searchValue, toggleStore]);

      const removeProductFromArr = (productId: number) => {
        const filterArray = props.products.filter((product) => {
          return product.id !== productId;
        });

        props.setProducts(filterArray);
      };

      const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        console.log(page);
        setCurrentPage(page);
      }

      const renderBody = () => {
        return <>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <div className="pagination">
              <Pagination count={maxPages} page={currentPage} onChange={handlePaginationChange} size="large"/>
            </div>
            <div className="toggleContainer">
              <div style={{marginRight: '50px', fontWeight: 'bolder', fontSize: '24px'}}>
                Totali: {totalStock}€
              </div>
              <button className={`toggle-button ${toggleStore === 'qendra'? "" : "active" }`}
                      onClick={() => setToggleStore((prev) => prev == 'qendra'? 'nertil' : 'qendra')}
              >
                <div>
                </div>
                <p className="qendra">Qendra</p>
                <p className="nertil">Nertil</p>
              </button>
            </div>
          </div>

          <div className="productTable">
            <table>
              <tr>
                <th>ID</th>
                <th>Emri</th>
                <th>Kategoria</th>
                <th>Dyqani</th>
                <th>Qmimi</th>
                <th>Stoku</th>
                <th>Totali</th>
                <th>+/- Stok</th>
                <th></th>
              </tr>
              {props.products.map((element: any) =>
                  <TableRow
                      removeProductFromArr={removeProductFromArr}
                      key={element.id} dataFiller={element}
                  />,
              )}
            </table>
          </div>
        </>;
      };

      return (
          <>
            {props.products
                ?
                renderBody()
                :
                <Loader style={{ width: '100%' }}/>
            }
          </>

      );
    }
;

const mapStateToProps = (state: any) => {
      return {};
    }
;

const mapDispatchToProps = (dispatch: any) => {
      return {};
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable);

import { isLogged, isntLogged } from './counter.types';


export const logIn = () => {
    return {
        type: isLogged,
    };

};

export const logOut = () => {
    return {
        type: isntLogged,
    };

};
import {axiosInstance} from '../utils/api';

export const loadCategories = (shopId: number) => {
  return axiosInstance().get(`/category/show?shop_id=${shopId}`);
}

export const createCategory = (data: any) => {
  return axiosInstance().post(`/category/create`, data);
}

export const loadAllCategories = () => {
  return axiosInstance().get(`/category/all`);
}

export const deleteCategory = (id: number): Promise<any> => {
  return axiosInstance().delete(`/category/delete/${id}`);
}
// export const updateCategory = (categoryId: number, category: any) => {
//   return axiosInstance().post(`/category/update/${categoryId}`, { category: category});
// }

// export const deleteCategory = (categoryId: number) => {
//   return axiosInstance().delete(`/category/delete/${categoryId}`);
// }


import React from 'react';
import ProductBarcode from './ProductBarcode';
import './printComponents.scss'
import _ from 'lodash';

interface Props {
  productId: number;
  productPrice: number;
  productName: string;
}

export class PrintProducts extends React.PureComponent<Props> {
  render() {
    return (
      <>
        <div className='printContainer'>
          {_.times(40, () => (
              <ProductBarcode
                  key={Math.random()}
                  productName={this.props.productName}
                  barcodeId={this.props.productId}
                  productPrice={this.props.productPrice}
              />
          ))}
        </div>
      </>
  );
  }
}
